import React from "react";
import { styled } from "styled-components";

export default function ErrorPage() {
  return (
    <Container>
      <p>
        Looks like you are getting lost into the nothingness.{" "}
        <button onClick={() => window.history.back()}>Get Back</button>.
      </p>
    </Container>
  );
}

const Container = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  width: 100dvw;
  text-align: center;
  font-size: 1.3rem;
  background-image: url("/bg/bg.webp");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  font-weight: bold;
  color: #ccc;
  & > p > button {
    font-family: inherit;
    font-size: inherit;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
  }
`;
