import React from "react";
import { styled } from "styled-components";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { LuLayoutDashboard } from "react-icons/lu";
import { BsGraphUp, BsClipboardDataFill } from "react-icons/bs";

export default function Home() {
  const Features = [
    {
      icon: <HiClipboardDocumentList className="ico" />,
      text: "Custom Form with Custom design",
    },
    {
      icon: <LuLayoutDashboard className="ico" />,
      text: "Custom Dashboard with/out Custom design",
    },
    {
      icon: <BsGraphUp className="ico" />,
      text: "Custom graphs",
    },
    {
      icon: <BsClipboardDataFill className="ico" />,
      text: "Custom Feedback Generation",
    },
  ];
  return (
    <>
      <Container>
        <section className="hero">
          <div className="content">
            <h1>
              Collect Data for your
              <br />
              Dream Survey.
            </h1>
            <p>
              Create the best custom survey experience with{" "}
              <a
                href="http://ahjim.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Akhlak
              </a>
              .
            </p>
          </div>
        </section>
        <section className="form">
          <div className="content">
            <h2>
              What Can We <br />
              Build Together?
            </h2>
            <p>
              "Unlocking the Power of Data Collection" - Together we can build
              an intuitive interface that effortlessly facilitates survey
              creation. Which can tailor questions that inspires, keeps user to
              response, and connects with users by infusing your brand's
              identity into the design.
              <br />
            </p>
            <a
              href={`mailto:aklajim@yahoo.com?subject=Let's build a Survey together!&body=Hi Akhlak, I was going through your Forms website and hope we can create a form togeter. Here is a few details about what I was thinking, ...`}
            >
              Contact Akhlak &rarr;
            </a>
          </div>
          <div className="card">
            <div className="image">
              <img src="/bg/home/form.webp" alt="" />
            </div>
            <a href="/demo">See a Demo &rarr;</a>
          </div>
        </section>
        <section className="features">
          <div className="content">
            <h2>What's Included?</h2>
            <div className="grid">
              {React.Children.toArray(
                Features.map((item) => (
                  <div className="feature">
                    {item.icon} {item.text}
                  </div>
                ))
              )}
            </div>
          </div>
        </section>
        <section className="connect">
          <div className="content">
            <h2>Let's Connect the Dots.</h2>
            <p>
              Every piece of information matters and holds the potential to
              create a bigger picture. Just like connecting the dots in a
              puzzle, we are here to help you find meaningful patterns and
              insights from your data. Join us on this exciting journey of
              discovery as we connect the dots and unlock the true potential of
              your data. Together, we'll pave the way for informed
              decision-making and a brighter future.
            </p>
            <a
              href={`mailto:aklajim@yahoo.com?subject=Let's build a Survey together!&body=Hi Akhlak, I was going through your Forms website and hope we can create a form togeter. Here is a few details about what I was thinking, ...`}
            >
              Let's get started!
            </a>
          </div>
        </section>
        <footer>
          &copy; 2023
          {new Date().getFullYear() !== 2023 &&
            `-${new Date().getFullYear()}`}{" "}
          All design and content is created by{" "}
          <a href="http://ahjim.com" target="_blank" rel="noopener noreferrer">
            Akhlak
          </a>
          .
        </footer>
      </Container>
    </>
  );
}

const Container = styled.main`
  font-family: var(--inter);
  width: min(1440px, 100%);
  margin: 0 auto;
  --primary: rgb(36, 55, 64);
  --primary-light: rgb(52 77 81);
  --secondary: rgb(150 171 171);
  --secondary-light: rgb(199, 218, 213);
  --light: wheat;
  --dark: black;

  background-color: var(--secondary-light);
  color: var(--primary);
  & > .hero {
    background-image: url("/bg/home/home.webp");
    background-position: top left;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    padding: 24px;
    height: min(100dvh, 900px);
    & > .content {
      padding: 24px;
      margin: auto;
      width: min(100%, 500px);
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(18.5px);
      text-align: center;
      border-radius: 24px;
      color: white;
      display: flex;
      flex-direction: column;
      gap: 16px;
      & > h1 {
        font-size: 2.5rem;
      }
      & > p {
        font-size: 1.2rem;
        & > a {
          color: var(--light);
        }
      }
    }
  }
  & > .form {
    width: min(1148px, 100%);
    margin: 0 auto;
    padding: 96px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    @media (max-width: 580px) {
      flex-direction: column;
    }
    & > .content {
      flex: 1;
      text-align: center;
      & > h2 {
        font-size: 2.4rem;
      }
      & > p {
        width: min(700px, 100%);
        margin: 24px auto;
      }
      & > a {
        margin: 24px auto;
        color: var(--primary);
        font-weight: bold;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    & > .card {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      & > .image {
        margin: auto;
        width: 90%;
        aspect-ratio: 16/8;
        border-radius: 24px;
        overflow: hidden;
        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      & > a {
        text-decoration: none;
        color: var(--primary);
        padding: 8px 32px;
        border: 2px solid var(--primary);
        font-weight: bold;
        border-radius: 24px;
        margin: 0 auto;
      }
    }
  }
  & > .features {
    background-image: url("/bg/home/features.webp");
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    height: min(100dvh, 900px);
    padding: 48px 24px;
    display: flex;
    & > .content {
      padding: 24px;
      width: min(800px, 100%);
      margin: auto;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(18.5px);
      border-radius: 8px;
      color: var(--secondary-light);
      & > h2 {
        font-size: 2.4rem;
        text-align: center;
      }
      & > .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
        gap: 24px;
        margin-top: 48px;
        & > .feature {
          background-color: var(--primary-light);
          padding: 24px;
          border-radius: 8px;
          color: var(--secondary-light);
          display: flex;
          gap: 16px;
          align-items: center;
          font-size: 1.2rem;
          & > .ico {
            font-size: 2rem;
          }
        }
      }
    }
  }
  & > .connect {
    background-image: url("/bg/home/connect.webp");
    background-position: bottom center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    height: min(100dvh, 900px);
    padding: 48px 24px;
    display: flex;
    & > .content {
      padding: 24px;
      width: min(800px, 100%);
      margin: auto;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(18.5px);
      border-radius: 8px;
      color: var(--secondary-light);
      display: flex;
      flex-direction: column;
      gap: 24px;
      text-align: center;
      & > h2 {
        font-size: 2.4rem;
      }
      & > p {
        font-size: 1.5rem;
      }
      & > a {
        text-decoration: none;
        color: var(--light);
        padding: 8px 32px;
        border: 2px solid var(--light);
        border-radius: 24px;
        margin: auto;
      }
    }
  }
  & > footer {
    padding: 24px;
    background-color: var(--primary);
    text-align: center;
    color: var(--secondary-light);
    & > a {
      color: var(--secondary-light);
    }
  }
`;
