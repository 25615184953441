import React, { useState } from "react";
import { styled } from "styled-components";
import FormCon from "../FormCon";
import OptionBox from "../OptionBox";

export default function GenderForm({ now, next, bangla }) {
  const [Gen, setGen] = useState();

  const genders = [
    { name: "Male", value: "male" },
    { name: "Female", value: "female" },
    { name: "Other", value: "other" },
  ];

  const handleNext = () => {
    let obj;
    if (
      sessionStorage.res &&
      sessionStorage.res !== null &&
      sessionStorage.res !== undefined &&
      sessionStorage.res !== ""
    ) {
      let o = JSON.parse(sessionStorage.res);
      if (typeof o === "object") obj = o;
      else obj = {};
    } else obj = {};
    obj = { ...obj, gender: Gen };
    sessionStorage.res = JSON.stringify(obj);
    sessionStorage.step = now + 1;
    next(now + 1);
  };

  return (
    <Container>
      <FormCon>
        {/* {!bangla ? ( */}
        <>
          <h2>Provide your Gender Information:</h2>
          <small>Choose your preferred gender.</small>
        </>
        {/* // ) : (
        //   <h2>
        //     আপনি নিজেকে কোন লিঙ্গের মানুষ হিশেবে পরিচিত হতে স্বাচ্ছন্দ্য বোধ
        //     করেন?{" "}
        //   </h2>
        // )} */}
        <OptionBox options={genders} selected={Gen} set={setGen} />
        <button className="eng102_next" disabled={!Gen} onClick={handleNext}>
          Next
        </button>
      </FormCon>
    </Container>
  );
}

const Container = styled.div``;
