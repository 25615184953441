import React, { useEffect, useState } from "react";
import { styled } from "styled-components";

export default function Feedback() {
  const [Res, setRes] = useState(1);
  const [MH, setMH] = useState();
  const [SL, setSL] = useState();
  const [Decision, setDecision] = useState();

  const MHA = {
    P: "Protective",
    O: "Open",
    Y: "Open yet Protective",
  };
  const SLA = {
    B: 'Balanced between "Me" and "We" person',
    M: 'A "Me" person',
    W: 'A "We" person',
  };
  const DA = {
    U: "Prioritizes the greater good",
    D: "Does the right thing, no matter what",
    S: "Strive for a balance between benefits and morals",
  };

  useEffect(() => {
    let obj;
    if (
      sessionStorage.res &&
      sessionStorage.res !== null &&
      sessionStorage.res !== undefined &&
      sessionStorage.res !== ""
    ) {
      let o = JSON.parse(sessionStorage.res);
      if (typeof o === "object") obj = o;
      else obj = {};
    } else obj = {};
    setRes(obj);
    let sum = 0;
    let arr = [
      "batman",
      "brain_tumor",
      "call_rape",
      "change_path",
      "ignore_to_save_boat",
      "impregnating",
      "kill_gangster",
      "kill_newborn",
      "kill_prisoner",
      "kill_to_save",
      "lie_outfit",
      "pain_transfer",
      "unknown_rape",
    ];
    for (let i = 0; i < 13; i++) {
      sum += obj[arr[i]];
    }
    const MHS = () => {
      if (obj.mentalState.total / 6 > 0) {
        if (obj.mentalState.total / 6 < 3) {
          return "P";
        } else if (obj.mentalState.total / 6 > 3) {
          return "O";
        } else if (obj.mentalState.total / 6 === 3) {
          return "Y";
        }
      }
    };
    const SLS = () => {
      if (obj.selfLove.total / 4 > 0) {
        if (obj.selfLove.total / 4 < 3) {
          return "W";
        } else if (obj.selfLove.total / 4 > 3) {
          return "M";
        } else if (obj.selfLove.total / 4 === 3) {
          return "B";
        }
      }
    };
    const DS = () => {
      if (sum / Number(obj.total) > 0) {
        if (sum / Number(obj.total) < 3) {
          return "D";
        } else if (sum / Number(obj.total) > 3) {
          return "U";
        } else if (sum / Number(obj.total) === 3) {
          return "S";
        }
      }
    };
    setMH(MHS);
    setSL(SLS);
    setDecision(DS);
  }, []);

  // console.log(Res);

  return (
    <Container>
      <h1>Thanks for your response.</h1>
      <p>
        I extend my heartfelt thanks to you for taking the time to participate
        in my research survey on decision-making tendencies through utilitarian
        and deontological lenses. Your valuable insights and thoughtful
        responses have made a significant contribution to my study. I hope you
        have enjoyed the survey as much as I have enjoyed building it.
      </p>
      <div className="card">
        <img src="/icon/wheel.png" alt="wheel illustration" />
        <h2>Your Decision-making wheel is:</h2>
        <h2 className="title">{MH + SL + Decision}</h2>
        <p>
          <strong>
            {MH && MHA[MH]}
            {SL && ` ● ` + SLA[SL]}
            {Decision && ` ● ` + DA[Decision]}
          </strong>
        </p>
      </div>
      {/* {MH && (
        <>
          <h2>{MH && MHA[MH]}</h2>
          <p>{MH}</p>
        </>
      )}
      {SL && (
        <>
          <h2>Your Self-Love tendency:</h2>
          <p>{SL}</p>
        </>
      )}
      {Decision && (
        <>
          <h2>Your Decision making tendency:</h2>
          <p>{Decision}</p>
        </>
      )} */}
      <span></span>
      <small>
        Note: above discussions are automatically generated biased on the making
        average of your responses.
      </small>
    </Container>
  );
}

const Container = styled.section`
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 1.3rem;
  width: min(100%, 800px);
  padding: 64px 0;
  h1,
  h2,
  p,
  small {
    text-align: center;
  }
  h2 {
    font-size: 1.5rem;
  }
  & > .card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: min(800px, 100%);
    padding: 40px 0;
    & > img {
      width: min(90%, 100px);
      aspect-ratio: 1/1;
      margin: auto;
      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      animation: rotate 12s linear infinite;
    }
    & > .title {
      font-size: 2.5rem;
      font-weight: 900;
    }
    & > p {
      font-size: 1.2rem;
    }
  }
`;
