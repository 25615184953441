import React, { useState } from "react";
import { styled } from "styled-components";
import OptionBox from "../OptionBox";

export default function BG2({ now, next }) {
  const [Ans1, setAns1] = useState();
  const [Ans2, setAns2] = useState();
  const [Ans3, setAns3] = useState();
  const [Ans4, setAns4] = useState();

  const Questions = [
    {
      que: "how much do you let your guard down while hanging out with people you’re comfortable with?",
      state: Ans1,
      setState: setAns1,
      op: [
        { name: "Never", value: 1 },
        { name: "Rarely", value: 2 },
        { name: "Depends", value: 3 },
        { name: "Most of the time", value: 4 },
        { name: "All the time", value: 5 },
      ],
    },
    {
      que: "how would you rank yourself as a friend?",
      state: Ans2,
      setState: setAns2,
      op: [
        { name: "Very Bad", value: 1 },
        { name: "Bad", value: 2 },
        { name: "Balanced", value: 3 },
        { name: "Good", value: 4 },
        { name: "Very Good", value: 5 },
      ],
    },
    {
      que: "how likely do you want a close friend just like yourself? Who is a direct copy of everything you are.",
      state: Ans3,
      setState: setAns3,
      op: [
        { name: "Not at all", value: 1 },
        { name: "Maybe", value: 2 },
        { name: "Might not be bad", value: 3 },
        { name: "Might be good", value: 4 },
        { name: "Very much", value: 5 },
      ],
    },
    {
      que: "how likely do you feel your lover or life partner should be the same as you are now, and he/she should think and like the same things as you do?",
      state: Ans4,
      setState: setAns4,
      op: [
        { name: "Not in any way", value: 1 },
        { name: "Might not be a bad", value: 2 },
        { name: "With some changes, Yes", value: 3 },
        { name: "That's a good Idea", value: 4 },
        { name: "100% alike", value: 5 },
      ],
    },
  ];

  //   You don't feel to open with your family and friends, you might have a tough relation with your parents, you might have a tough childhood
  //   You feel balanced with respect to your relation with friends and parents, and about your childhood

  const handleNext = () => {
    let obj;
    if (
      sessionStorage.res &&
      sessionStorage.res !== null &&
      sessionStorage.res !== undefined &&
      sessionStorage.res !== ""
    ) {
      let o = JSON.parse(sessionStorage.res);
      if (typeof o === "object") obj = o;
      else obj = {};
    } else obj = {};
    obj = {
      ...obj,
      selfLove: {
        guard_down: Ans1,
        rank_as_friend: Ans2,
        friend_alike: Ans3,
        partner_alike: Ans4,
        total: Ans1 + Ans2 + Ans3 + Ans4,
      },
    };
    sessionStorage.res = JSON.stringify(obj);
    sessionStorage.step = now + 1;
    next(now + 1);
    window.scrollTo(0, 0);
  };

  return (
    <Container>
      <h2>Dive into self-love:</h2>
      {React.Children.toArray(
        Questions.map((el) => (
          <>
            <p>On a scale of one to five, {el.que}</p>
            <OptionBox
              options={el.op}
              selected={el.state}
              set={el.setState}
              size="small"
            />
          </>
        ))
      )}
      <span></span>
      <button
        className="eng102_next"
        disabled={!(Ans1 && Ans2 && Ans3 && Ans4)}
        onClick={handleNext}
      >
        Next
      </button>
    </Container>
  );
}
const Container = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
