import React from "react";
import { styled } from "styled-components";

export default function OptionBox({ options, selected, set, size = "normal" }) {
  return (
    <Container>
      {React.Children.toArray(
        options.map((el) => (
          <button
            className={`${size} ${el.value === selected ? "selected" : ""}`}
            onClick={() => set(el.value)}
          >
            {el.name}
          </button>
        ))
      )}
    </Container>
  );
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 24px;
  & > button {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    background-color: transparent;
    border: 1px solid var(--green);
    outline: none;
    padding: 12px 24px;
    &.small {
      font-size: 80%;
      padding: 8px 24px;
    }
    &.large {
      font-size: 140%;
    }
    &.selected {
      background-color: var(--green);
      color: #ccc;
    }
  }
`;
