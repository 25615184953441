import React from "react";
import { Route, Routes } from "react-router-dom";
import { styled } from "styled-components";
import Login from "./pages/Login";
import Eng102 from "./pages/Eng102";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import Demo from "./pages/Demo";

export default function App() {
  return (
    <Container>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/bidWl93jPQD" element={<Eng102 />} />
        <Route path="/bidWl93jPQD." element={<Eng102 />} />
        <Route path="/TQToyMBJ0mqzf81Tt7q64IfsDlFw05Log" element={<Login />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Container>
  );
}

const Container = styled.div`
  /* width: min(1440px, 100%);
  margin: 0 auto; */
`;
