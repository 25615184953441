export function randomString() {
  let S = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  let s = "abcdefghijklmnopqrstuvwxyz".split("");
  let n = "012345678901234567890123456789".split("");
  let arr = [...S, ...s, ...n];
  let str = "";
  for (let i = 0; i < 8; i++) {
    let x = Math.round(Math.random() * arr.length - 1);
    str += arr[x];
  }
  return str.substring(0, 8);
}
