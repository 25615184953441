import React from "react";
import { styled } from "styled-components";

export default function FormCon({ children }) {
  return <Container>{children}</Container>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 0;
  & > label {
    font-size: 1.2rem;
  }
  & > input {
    padding: 6px 12px;
    font-size: 1.2rem;
    font-weight: 500;
    border: 2px solid #000;
    border-radius: 8px;
  }
`;
