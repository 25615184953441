import React, { useState } from "react";
import { styled } from "styled-components";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { IoMdCheckboxOutline } from "react-icons/io";
import ModalContainer from "../ModalContainer";
import TAndCCon from "./TAndCCon";

export default function Welcome({ now, next }) {
  const [Agree, setAgree] = useState(false);
  const [TAndC, setTAndC] = useState(false);
  return (
    <>
      <Container>
        <h1>Welcome to knowing yourself through Decision-Making Tendencies!</h1>
        <p>
          Ready to explore the fascinating realm of decision-making through
          utilitarian and deontological lenses. By participating in this survey,
          you are embarking on a journey of self-discovery and contributing to a
          deeper understanding of ethical choices. Your valuable insights will
          help us uncover the complexities of decision-making processes. Thank
          you for joining us on this exciting adventure of knowledge and
          personal growth!
        </p>
        <p>
          <strong>
            At the end of the survey, I hope to paint a picture of your
            Decision-making wheel based on your responses.
          </strong>
        </p>
        <p>
          <strong>
            Note: To facilitate easy understanding of the story-based questions,
            I have included Bangla translations of the stories only.
            (গল্পভিত্তিক প্রশ্ন সমূহের গল্প গুলোকে সহজ ভাবে বোঝাতে শুধুমাত্র
            গল্প গুলোকে বাংলায় পড়ার ব্যাবস্থা করা হয়েছে।) Everything else (basic
            short questions, options, and instructions, including feedback, are
            in the English Language only.)
          </strong>
        </p>
        <small>
          <button
            className={`icon ${Agree ? "checked" : ""}`}
            onClick={() => setAgree((e) => !e)}
          >
            {Agree ? (
              <IoMdCheckboxOutline />
            ) : (
              <MdOutlineCheckBoxOutlineBlank />
            )}
          </button>{" "}
          <span>
            I hereby agree to the{" "}
            <button onClick={() => setTAndC(true)}>Terms & Conditions</button>{" "}
            of this survey.
          </span>
        </small>
        <button
          disabled={!Agree}
          onClick={() => {
            if (Agree) {
              sessionStorage.step = now + 1;
              next(now + 1);
            }
          }}
        >
          Next
        </button>
      </Container>
      <ModalContainer show={TAndC} setShow={setTAndC}>
        <TAndCCon agree={setAgree} setShow={setTAndC} />
      </ModalContainer>
    </>
  );
}

const Container = styled.section`
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 1.3rem;
  h1 {
    text-align: center;
  }
  p {
    text-align: justify;
  }
  & > small {
    display: flex;
    align-items: start;
    gap: 6px;
    & > .icon {
      color: #f4d485;
      font-size: 1.4rem;
      margin: 0;
      background-color: transparent;
      border: none;
      outline: none;
      /* margin-top: 2px; */
      cursor: pointer;
      &.checked {
        color: #2d423b;
      }
    }
    & > span > button {
      font-family: inherit;
      font-size: inherit;
      background-color: transparent;
      border: none;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  & > button {
    background: linear-gradient(100deg, #2d423b 0%, #375148 100%);
    border: none;
    outline: none;
    color: #ccc;
    font-size: inherit;
    padding: 12px min(30%, 100px);
    margin-left: auto;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 5px 5px 10px #121a18, -5px -5px 10px #121a18;
    &:disabled {
      filter: grayscale(100%);
      box-shadow: none;
    }
  }
`;
