import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { auth } from "../utils/firebase";
import DashBoard from "../components/DashBoard";

export default function Login() {
  const [Email, setEmail] = useState();
  const [Password, setPassword] = useState();
  const [Error, setError] = useState();
  const [LoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    let user = sessionStorage.user;
    user && JSON.parse(user).uid && setLoggedIn(true);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    Email &&
      Password &&
      signInWithEmailAndPassword(auth, Email, Password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          setLoggedIn(true);
          sessionStorage.user = JSON.stringify(user);
        })
        .catch((error) => {
          setError(error);
          console.log(error);
        });
  };
  return LoggedIn ? (
    <>
      <DashBoard />
    </>
  ) : (
    <Container>
      <div className="container">
        <h1>Sign in to you account.</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">Enter your email: *</label>
          <input
            type="email"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            id="email"
            required
          />
          <label htmlFor="password">Enter your Password: *</label>
          <input
            type="password"
            value={Password}
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            id="password"
            required
          />
          {Error && <p className="error">{Error.message}</p>}
          <button type="submit">Sign In</button>
        </form>
      </div>
    </Container>
  );
}

const Container = styled.main`
  display: flex;
  padding: 24px;
  min-height: 100dvh;
  background-color: #fff;
  color: #000;
  & > .container {
    width: min(100%, 500px);
    margin: auto;
    & > form {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 24px 0;
      & > label {
        font-size: 1.2rem;
      }
      & > input {
        padding: 6px 12px;
        font-size: 1.2rem;
        font-weight: 500;
        border: 2px solid #000;
        border-radius: 8px;
      }
      & > button {
        font-size: 1.2rem;
        font-weight: 500;
        padding: 6px 12px;
        border: 2px solid #000;
        border-radius: 8px;
        outline: none;
        background-color: transparent;
        color: #000;
        &:hover,
        &:focus {
          color: #fff;
          background-color: #000;
        }
      }
    }
  }
`;
