import React from "react";
import { styled } from "styled-components";
import { GrClose } from "react-icons/gr";

export default function ModalContainer({ show, setShow, children }) {
  return (
    show && (
      <Container>
        <div role="button" className="back" onClick={() => setShow(false)} />
        <div className="container">
          <button className="close" onClick={() => setShow(false)}>
            <GrClose />
          </button>
          {children}
        </div>
      </Container>
    )
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100dvh;
  width: 100dvw;
  overflow: hidden;
  background-color: var(--black-tr);
  display: flex;
  padding: 24px;
  & > .back {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  & > .container {
    width: min(800px, 100%);
    margin: auto;
    background-color: #c2a0ae;
    padding: 24px;
    border-radius: 12px;
    height: 95%;
    overflow: hidden;
    position: relative;
    & > .close {
      position: absolute;
      top: 20px;
      right: 20px;
      height: max-content;
      font-size: 1.4rem;
      font-weight: bold;
      background-color: transparent;
      border: none;
      outline: none;
      line-height: 100%;
      cursor: pointer;
      &:focus {
        outline: 1px dashed inherit;
      }
    }
    & > * {
      height: 100%;
      overflow-y: auto;
    }
  }
`;
