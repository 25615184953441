import React, { useState } from "react";
import { styled } from "styled-components";
import OptionBox from "../OptionBox";

export default function BG1({ now, next }) {
  const [Ans1, setAns1] = useState();
  const [Ans2, setAns2] = useState();
  const [Ans3, setAns3] = useState();
  const [Ans4, setAns4] = useState();
  const [Ans5, setAns5] = useState();
  const [Ans6, setAns6] = useState();

  const Questions = [
    {
      que: "how happy do you feel with your family?",
      state: Ans1,
      setState: setAns1,
      op: [
        { name: "Very Unhappy", value: 1 },
        { name: "Unhappy", value: 2 },
        { name: "Depends", value: 3 },
        { name: "Happy", value: 4 },
        { name: "Very happy", value: 5 },
      ],
    },
    {
      que: "how happy do you feel with your friends?",
      state: Ans2,
      setState: setAns2,
      op: [
        { name: "Very Unhappy", value: 1 },
        { name: "Unhappy", value: 2 },
        { name: "Depends", value: 3 },
        { name: "Happy", value: 4 },
        { name: "Very happy", value: 5 },
      ],
    },
    {
      que: "Looking back to your childhood, how joyful it was?",
      state: Ans3,
      setState: setAns3,
      op: [
        { name: "Not at all", value: 1 },
        { name: "Somewhat joyful", value: 2 },
        { name: "Mixed", value: 3 },
        { name: "Joyful", value: 4 },
        { name: "Very much", value: 5 },
      ],
    },
    {
      que: "how much do you want be a mom or dad like your own father or mother?",
      state: Ans4,
      setState: setAns4,
      op: [
        { name: "No Way", value: 1 },
        { name: "Will Try not to", value: 2 },
        { name: "With some changes, Yes", value: 3 },
        { name: "I can, Yes", value: 4 },
        { name: "100% like my parents", value: 5 },
      ],
    },
    {
      que: "how likely are you to share your thoughts with your parents, without any hesitation as opposed to your/best friend?",
      state: Ans5,
      setState: setAns5,
      op: [
        { name: "No one", value: 1 },
        { name: "Friends", value: 2 },
        { name: "Depends", value: 3 },
        { name: "Only Family", value: 4 },
        { name: "Both of them are fine", value: 5 },
      ],
    },
    {
      que: "how safe do you feel around the people you consider to be close?",
      state: Ans6,
      setState: setAns6,
      op: [
        { name: "Subconsciously Protective to myself", value: 1 },
        { name: "Somewhat Protective to myself", value: 2 },
        { name: "Neither Safe or Unsafe", value: 3 },
        { name: "Somewhat feel safe", value: 4 },
        { name: "Very Safe, like peace of mind", value: 5 },
      ],
    },
  ];

  //   You don't feel to open with your family and friends, you might have a tough relation with your parents, you might have a tough childhood
  //   You feel balanced with respect to your relation with friends and parents, and about your childhood

  const handleNext = () => {
    let obj;
    if (
      sessionStorage.res &&
      sessionStorage.res !== null &&
      sessionStorage.res !== undefined &&
      sessionStorage.res !== ""
    ) {
      let o = JSON.parse(sessionStorage.res);
      if (typeof o === "object") obj = o;
      else obj = {};
    } else obj = {};
    obj = {
      ...obj,
      mentalState: {
        happy_fam: Ans1,
        happy_friend: Ans2,
        childhood: Ans3,
        good_parent: Ans4,
        fndOrFam: Ans5,
        safe: Ans6,
        total: Ans1 + Ans2 + Ans3 + Ans4 + Ans5 + Ans6,
      },
    };
    sessionStorage.res = JSON.stringify(obj);
    sessionStorage.step = now + 1;
    next(now + 1);
    window.scrollTo(0, 0);
  };

  return (
    <Container>
      <h2>Dive into your past and Relationships:</h2>
      {React.Children.toArray(
        Questions.map((el) => (
          <>
            <p>On a scale of one to five, {el.que}</p>
            <OptionBox
              options={el.op}
              selected={el.state}
              set={el.setState}
              size="small"
            />
          </>
        ))
      )}
      <span></span>
      <button
        className="eng102_next"
        disabled={!(Ans1 && Ans2 && Ans3 && Ans4 && Ans5 && Ans6)}
        onClick={handleNext}
      >
        Next
      </button>
    </Container>
  );
}
const Container = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
