import React, { useEffect, useState } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";
import { auth, db } from "../utils/firebase";
import { signOut } from "firebase/auth";
import { styled } from "styled-components";

export default function DashBoard() {
  const [UserData, setUserData] = useState([]);
  const [Data, setData] = useState([]);
  const [Points, setPoints] = useState([]);
  const [SDPoints, setSDPoints] = useState([]);
  const [SDPointsData, setSDPointsData] = useState();

  const [Wheels, setWheels] = useState([]);

  const keysArr = [
    "ID",
    "Gender",
    "Mental State",
    "Self Love",
    "Change Path",
    "Pain Transfer",
    "Lie OutFit",
    "Brain Tumor",
    "Impregnating",
    "Unknown Rapist",
    "Call a Rape",
    "Batman",
    "Kill in forest",
    "Killing the Gangster",
    "Ignore Boat",
    "Kill The Prisoner",
    "Kill all Newborn",
    "Total Answered",
  ];

  function SignOut() {
    signOut(auth)
      .then(() => {
        sessionStorage.removeItem("user");
        window.location.reload();
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    if (sessionStorage.user) {
      let data = JSON.parse(sessionStorage.user);
      setUserData(data);
      let arr = [];
      const qu = query(collection(db, "Eng102"));
      onSnapshot(qu, (snapshot) => {
        snapshot.forEach((item) => {
          if (item.type === "removed") {
            //remove item code
            console.log(item.id);
            arr = [...arr.filter((el) => el.id !== item.id)];
            // setData((G) => [...G.filter((el) => el.id !== item.id)]);
          } else {
            arr.push({ id: item.id, ...item.data() });
            // setData((G) => [...G, { id: item.id, ...item.data() }]);
          }
        });
        setData(arr);

        let sum = (el) => {
          let sum = 0;
          let ks = [
            "batman",
            "brain_tumor",
            "call_rape",
            "change_path",
            "ignore_to_save_boat",
            "impregnating",
            "kill_gangster",
            "kill_newborn",
            "kill_prisoner",
            "kill_to_save",
            "lie_outfit",
            "pain_transfer",
            "unknown_rape",
          ];
          for (let i = 0; i < 13; i++) {
            sum += el[ks[i]];
          }
          let sum2 = 0;
          for (let i = 0; i < 13; i++) {
            sum2 += (el[ks[i]] - sum / el.total) ** 2;
          }
          let sd = Math.sqrt(sum2 / 13);
          return { sum, sd };
        };

        let points = [...arr].map((el) => {
          return [
            ((Number(el.mentalState.total) + Number(el.selfLove.total)) / 50) *
              100,
            ((sum(el).sum / el.total) * 100) / 5,
          ];
        });

        const sd = (data) => {
          let arr2 = [...data.arr].map(
            (el) => Number((el - data.avg).toFixed(2)) ** 2
          );
          let sum2 = arr2.reduce((a, b) => Number((a + b).toFixed(4)), 0);
          let res = Math.sqrt(sum2 / data.n);
          return res;
        };

        let SDpoints = [...arr].map((el) => {
          return [
            (sd({
              arr: [
                el.mentalState.childhood,
                el.mentalState.fndOrFam,
                el.mentalState.good_parent,
                el.mentalState.happy_fam,
                el.mentalState.happy_friend,
                el.mentalState.safe,
              ],
              avg: el.mentalState.total / 6,
              n: 6,
            }) +
              sd({
                arr: [
                  el.selfLove.friend_alike,
                  el.selfLove.guard_down,
                  el.selfLove.partner_alike,
                  el.selfLove.rank_as_friend,
                ],
                avg: el.selfLove.total / 4,
                n: 4,
              })) *
              30,
            sum(el).sd * 30,
          ];
        });

        let ws = [...arr].map((el) => {
          return sum(el).sum / el.total;
        });

        let wsObj = () => {
          let obj = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
          for (let el of ws) {
            if (el >= 0 && el < 1) {
              obj[0]++;
            } else if (el >= 1 && el < 2) {
              obj[1]++;
            } else if (el >= 2 && el < 3) {
              obj[2]++;
            } else if (el >= 3 && el < 4) {
              obj[3]++;
            } else if (el >= 4 && el < 5) {
              obj[4]++;
            } else if (el >= 5 && el < 6) {
              obj[5]++;
            }
          }
          let max = Object.values(obj)[0];
          for (let el of Object.values(obj)) {
            if (el > max) max = el;
          }
          let ys = [];
          for (let i = Math.ceil((max + 1) / 5); i > 0; i--) {
            ys.push(i * 5);
          }
          return { ...obj, max, ys };
        };

        const minMax = (data, item, div) => {
          let min = data[0][item] / div;
          let max = data[0][item] / div;
          for (let el of data) {
            if (min > el[item] / div) {
              min = el[item] / div;
            }
            if (max < el[item] / div) {
              max = el[item] / div;
            }
          }
          return { min, max: Math.ceil(max) };
        };

        setWheels(wsObj);
        setPoints(points);
        setSDPoints(SDpoints);
        setSDPointsData({
          xMax: minMax(SDpoints, 1, 30).max,
          yMax: minMax(SDpoints, 0, 30).max,
        });
      });
    } else {
      sessionStorage.removeItem("user");
      window.location.reload();
    }
  }, []);

  return (
    <Container>
      <div className="data">
        <div className="stat">
          <div className="card_stat total">
            <h5>Total Responses</h5>
            <h2>{Data.length}</h2>
          </div>
          <div className="card_stat male">
            <h5>Male Responses</h5>
            <h2>
              {Array.isArray(Data) &&
                Data.length > 0 &&
                Data.filter((el) => el.gender === "male").length}
            </h2>
            <small>
              {Array.isArray(Data) &&
                Data.length > 0 &&
                Math.round(
                  (Data.filter((el) => el.gender === "male").length /
                    Data.length) *
                    100
                )}
              %
            </small>
          </div>
          <div className="card_stat female">
            <h5>Female Responses</h5>
            <h2>
              {Array.isArray(Data) &&
                Data.length > 0 &&
                Data.filter((el) => el.gender === "female").length}
            </h2>
            <small>
              {Array.isArray(Data) &&
                Data.length > 0 &&
                Math.round(
                  (Data.filter((el) => el.gender === "female").length /
                    Data.length) *
                    100
                )}
              %
            </small>
          </div>
          <div className="card_stat other">
            <h5>Other Gender Responses</h5>
            <h2>
              {Array.isArray(Data) &&
                Data.length > 0 &&
                Data.filter((el) => el.gender === "other").length}
            </h2>
            <small>
              {Array.isArray(Data) &&
                Data.length > 0 &&
                Math.round(
                  (Data.filter((el) => el.gender === "other").length /
                    Data.length) *
                    100
                )}
              %
            </small>
          </div>
        </div>
        {/* Avg point Scatter Plot */}
        {Array.isArray(Points) && Points.length > 0 && (
          <>
            <ScatterPlot>
              <div className="plot">
                <div className="y-axis">
                  <p className="description">Overall mental state&rarr;</p>
                  <div className="indicators">
                    <span>5&mdash;</span>
                    <span>4&mdash;</span>
                    <span>3&mdash;</span>
                    <span>2&mdash;</span>
                    <span>1&mdash;</span>
                  </div>
                </div>
                <div className="x-axis">
                  <div className="indicators">
                    <span>
                      |<b>1</b>
                    </span>
                    <span>
                      |<b>2</b>
                    </span>
                    <span>
                      |<b>3</b>
                    </span>
                    <span>
                      |<b>4</b>
                    </span>
                    <span>
                      |<b>5</b>
                    </span>
                  </div>
                  <p className="description">
                    Overall decision-making tendencies&rarr;
                  </p>
                </div>
                <div className="dots">
                  {React.Children.toArray(
                    Points.map((el) => (
                      <span style={{ bottom: `${el[0]}%`, left: `${el[1]}%` }}>
                        &bull;
                      </span>
                    ))
                  )}
                </div>
                <div className="zero">0</div>
              </div>
            </ScatterPlot>
            <p>
              Figure: Scatter plot of avg score of mental health by avg score of
              scenario response.
            </p>
          </>
        )}

        {/* Standard Deviation point Scatter Plot */}
        {Array.isArray(SDPoints) && SDPoints.length > 0 && (
          <>
            <ScatterPlot>
              <div className="plot">
                <div className="y-axis">
                  <p className="description">SD of mental state&rarr;</p>
                  <div className="indicators">
                    <span>
                      {((SDPointsData.yMax || 5) / 1).toFixed(1)}&mdash;
                    </span>
                    <span>
                      {((SDPointsData.yMax || 5) / 2).toFixed(1)}&mdash;
                    </span>
                    <span>
                      {((SDPointsData.yMax || 5) / 3).toFixed(1)}&mdash;
                    </span>
                    <span>
                      {((SDPointsData.yMax || 5) / 4).toFixed(1)}&mdash;
                    </span>
                    <span>
                      {((SDPointsData.yMax || 5) / 5).toFixed(1)}&mdash;
                    </span>
                  </div>
                </div>
                <div className="x-axis">
                  <div className="indicators">
                    <span>
                      |<b>{((SDPointsData.xMax || 5) / 5).toFixed(1)}</b>
                    </span>
                    <span>
                      |<b>{((SDPointsData.xMax || 5) / 4).toFixed(1)}</b>
                    </span>
                    <span>
                      |<b>{((SDPointsData.xMax || 5) / 3).toFixed(1)}</b>
                    </span>
                    <span>
                      |<b>{((SDPointsData.xMax || 5) / 2).toFixed(1)}</b>
                    </span>
                    <span>
                      |<b>{((SDPointsData.xMax || 5) / 1).toFixed(1)}</b>
                    </span>
                  </div>
                  <p className="description">
                    SD of decision-making tendencies&rarr;
                  </p>
                </div>
                <div className="dots">
                  {React.Children.toArray(
                    SDPoints.map((el) => (
                      <span
                        style={{
                          bottom: `${el[0]}%`,
                          left: `${el[1]}%`,
                        }}
                      >
                        &bull;
                      </span>
                    ))
                  )}
                </div>
                <div className="zero">0</div>
              </div>
            </ScatterPlot>
            <p>
              Figure: Scatter plot of Standard deviation score of mental health
              by Standard deviation score of scenario response.
            </p>
          </>
        )}

        {/* Bar chart of tendency */}
        {Wheels && typeof Wheels === "object" && (
          <>
            <BarChart
              cols={Math.ceil((Wheels.max + 1) / 5)}
              rows={Object.keys(Wheels).length - 2}
            >
              <div className="plot">
                <div className="y-axis">
                  <div className="indicators">
                    {Wheels.ys &&
                      Array.isArray(Wheels.ys) &&
                      React.Children.toArray(
                        Wheels.ys.map((el) => (
                          <span>{el < 10 ? "0" + el : el}&mdash;</span>
                        ))
                      )}
                  </div>
                </div>
                <div className="x-axis">
                  <div className="indicators">
                    {React.Children.toArray(
                      Object.keys(Wheels).map(
                        (el) =>
                          el.toLowerCase() !== "max" &&
                          el.toLowerCase() !== "ys" && (
                            <span>
                              |<b>{el}</b>
                            </span>
                          )
                      )
                    )}
                  </div>
                </div>
                <div className="bars">
                  {React.Children.toArray(
                    Object.keys(Wheels).map(
                      (el) =>
                        el.toLowerCase() !== "max" &&
                        el.toLowerCase() !== "ys" && (
                          <div
                            style={{
                              height: `${
                                (Wheels[el] /
                                  (Math.ceil((Wheels.max + 1) / 5) * 5)) *
                                100
                              }%`,
                            }}
                          >
                            <span>
                              {
                                {
                                  1: "Extreme Deontological",
                                  2: "Somewhat Deontological",
                                  3: "Balanced",
                                  4: "Somewhat Utilitarian",
                                  5: "Extreme Utilitarian",
                                }[el]
                              }
                              ({Wheels[el]})
                            </span>
                          </div>
                        )
                    )
                  )}
                </div>
                {/* <div className="zero">0</div> */}
              </div>
            </BarChart>
            <p>Figure: Bar chart of Decision Making tendencies.</p>
          </>
        )}
        {Array.isArray(Data) && Data.length > 0 && (
          <div className="sheet">
            <table>
              <thead>
                <tr>
                  {React.Children.toArray(keysArr.map((el) => <th>{el}</th>))}
                </tr>
              </thead>
              <tbody>
                {React.Children.toArray(
                  Data.map((data) => (
                    <tr>
                      <th>{data.id}</th>
                      <th>{data.gender}</th>
                      <th>{data.mentalState.total}</th>
                      <th>{data.selfLove.total}</th>
                      <th>{data.change_path}</th>
                      <th>{data.pain_transfer}</th>
                      <th>{data.lie_outfit}</th>
                      <th>{data.brain_tumor}</th>
                      <th>{data.impregnating}</th>
                      <th>{data.unknown_rape}</th>
                      <th>{data.call_rape}</th>
                      <th>{data.batman}</th>
                      <th>{data.kill_to_save}</th>
                      <th>{data.kill_gangster}</th>
                      <th>{data.ignore_to_save_boat}</th>
                      <th>{data.kill_prisoner}</th>
                      <th>{data.kill_newborn}</th>
                      <th>{data.total}</th>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Container>
  );
}

const Container = styled.main`
  --pink: #e9bfde;
  --green: #d2e1b4;
  --blue: #d8f1ff;
  --yellow: #e1dbb4;

  width: 100%;
  overflow-x: hidden;
  & > .data {
    padding: 24px;
    & > p {
      text-align: center;
      font-weight: 600;
    }
    & > .stat {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
      gap: 12px;
      font-size: 125%;
      & > .card_stat {
        padding: 24px;
        border-radius: 8px;
        &.total {
          background-color: var(--green);
        }
        &.male {
          background-color: var(--blue);
        }
        &.female {
          background-color: var(--pink);
        }
        &.other {
          background-color: var(--yellow);
        }
      }
    }
    & > .sheet {
      margin: 24px 0;
      width: 100%;
      overflow-x: auto;
      & > table {
        background-color: rgba(0, 0, 0, 0.3);
        & > * > * > * {
          padding: 6px;
          background-color: #fff;
        }
      }
    }
  }
`;

const ScatterPlot = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  & > .plot {
    padding: 24px;
    padding-left: 0;
    display: grid;
    aspect-ratio: 2/1;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(6, 1fr);
    position: relative;
    width: 100%;
    overflow-y: visible;
    overflow-x: auto;
    @media (max-width: 580px) {
      aspect-ratio: 4/3;
    }
    & > .y-axis {
      grid-column: 1 / span 1;
      grid-row: 1 / span 5;
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      & > .description {
        transform: rotateZ(-180deg);
        transform-origin: center;
        /* width: 82%; */
        white-space: nowrap;
        writing-mode: vertical-lr;
      }
      & > .indicators {
        display: grid;
        grid-template-rows: repeat(5, 1fr);
        height: 100%;
        margin-left: auto;
        font-size: 120%;
        font-weight: bolder;
        & > span {
          transform: translateY(-12px);
          white-space: nowrap;
        }
      }
    }
    & > .x-axis {
      grid-column: 2 / span 11;
      grid-row: 6 / span 1;
      display: flex;
      align-items: center;
      justify-content: start;
      flex-direction: column;
      padding: 0 0 8px;
      gap: 0px;
      & > .indicators {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        width: 100%;
        text-align: right;
        font-size: 120%;
        font-weight: bolder;
        & > span {
          display: flex;
          flex-direction: column;
          justify-content: center;
          transform: translateX(2px) translateY(-6px);
          & > b {
            transform: translateX(3px);
          }
        }
      }
      & > .description {
        text-align: center;
      }
    }
    & > .dots {
      grid-column: 2 / span 11;
      grid-row: 1 / span 5;
      border-left: 1px solid #000;
      border-bottom: 1px solid #000;
      position: relative;
      & > * {
        position: absolute;
        transform: translate(-50%, 50%) scale(1.5);
      }
    }
    & > .zero {
      grid-column: 1 / span 1;
      grid-row: 6 / span 1;
      text-align: right;
      font-size: 120%;
      font-weight: bold;
    }
  }
`;

const BarChart = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  & > .plot {
    padding: 24px;
    padding-left: 0;
    display: grid;
    aspect-ratio: 2/1;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(6, 1fr);
    position: relative;
    width: 100%;
    overflow-y: visible;
    overflow-x: auto;
    @media (max-width: 580px) {
      aspect-ratio: 4/3;
    }
    & > .y-axis {
      grid-column: 1 / span 1;
      grid-row: 1 / span 5;
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      & > .description {
        transform: rotateZ(-180deg);
        transform-origin: center;
        /* width: 82%; */
        white-space: nowrap;
        writing-mode: vertical-lr;
      }
      & > .indicators {
        display: grid;
        grid-template-rows: repeat(${({ cols }) => (cols ? cols : 5)}, 1fr);
        height: 100%;
        margin-left: auto;
        font-size: 120%;
        font-weight: bolder;
        & > span {
          transform: translateY(-12px);
          white-space: nowrap;
        }
      }
    }
    & > .x-axis {
      grid-column: 2 / span 11;
      grid-row: 6 / span 1;
      display: flex;
      align-items: center;
      justify-content: start;
      flex-direction: column;
      padding: 0 0 8px;
      gap: 0px;
      & > .indicators {
        display: grid;
        grid-template-columns: repeat(${({ rows }) => (rows ? rows : 5)}, 1fr);
        width: 100%;
        text-align: right;
        font-size: 120%;
        font-weight: bolder;
        & > span {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          transform: translateX(-1px) translateY(-6px);
          & > b {
            margin: 0 auto;
          }
        }
      }
      & > .description {
        text-align: center;
      }
    }
    & > .bars {
      grid-column: 2 / span 11;
      grid-row: 1 / span 5;
      border-left: 1px solid #000;
      border-bottom: 1px solid #000;
      position: relative;
      display: grid;
      grid-template-columns: repeat(${({ rows }) => (rows ? rows : 5)}, 1fr);
      & > * {
        margin-top: auto;
        background-color: #000;
        border: 1px solid #fff;
        border-bottom: 0;
        text-align: center;
        position: relative;
        & > span {
          position: absolute;
          left: 50%;
          bottom: calc(100% + 5px);
          transform: translateX(-50%);
        }
      }
    }
    & > .zero {
      grid-column: 1 / span 1;
      grid-row: 6 / span 1;
      text-align: right;
      font-size: 120%;
      font-weight: bold;
    }
  }
`;
